import { TasteProfile, ThemeQuery } from '@generated/graphql';

export const translateProfile = (profile: TasteProfile) => ({
  [TasteProfile.Fris]: 'Fresh',
  [TasteProfile.Gebalanceerd]: 'Balanced',
  [TasteProfile.Krachtig]: 'Powerful',
  [TasteProfile.Licht]: 'Light',
  [TasteProfile.Opwekkend]: 'Stimulant',
  [TasteProfile.Rond]: 'Round',
  [TasteProfile.Stevig]: 'Firm',
  [TasteProfile.Vol]: 'Full',
}[profile]);

export const getCurrentLanguage = (themeData: Pick<NonNullable<ThemeQuery['theme']>, 'defaultLanguage'>) => (
  new URLSearchParams(window.location.search).get('language') || themeData.defaultLanguage
);

const getTranslation = (themeData: NonNullable<ThemeQuery['theme']>) => {
  const language = getCurrentLanguage(themeData);
  return (language && themeData.translations.find((t) => t.language === language)) || themeData.translations[0];
};

export default getTranslation;
