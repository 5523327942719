import React, { useState } from 'react';

import {
  Box, Button, Slider, Typography,
} from '@mui/material';

import { SliderQuestion } from '@generated/graphql';
import { AnswerData } from '../../Common/answer-data';

type SliderQuestionProps = {
  question: SliderQuestion,
  onAnswerSubmit: (question: number, answerData: AnswerData) => void
};

// Component to render a slider question
const SliderQuestionView = ({ question, onAnswerSubmit }: SliderQuestionProps) => {
  const [value, setValue] = useState(question.marks[0].value);

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 8 }}>{question.name}</Typography>

      <Box sx={{ width: '100%', px: 4 }}>
        <Slider
          value={value}
          onChange={(_, newValue) => setValue(newValue as number)}
          valueLabelDisplay={question.restrictToMarks ? 'off' : 'auto'}
          step={question.restrictToMarks ? null : 1}
          marks={question.marks}
          min={Math.min(...question.marks.map((mark) => mark.value))}
          max={Math.max(...question.marks.map((mark) => mark.value))}
        />
      </Box>

      <Box textAlign="center">
        <Button sx={{ mt: 8, mb: 1 }} onClick={() => onAnswerSubmit(question.id, { value })} disabled={value === undefined}>
          Doorgaan
        </Button>
      </Box>
    </>
  );
};

export default SliderQuestionView;
