import {
  ApolloClient, InMemoryCache, HttpLink, ApolloLink,
} from '@apollo/client';

import { RetryLink } from '@apollo/client/link/retry';

const client = new ApolloClient({
  link: ApolloLink.from([
    new RetryLink({
      delay: {
        initial: 300,
      },
      attempts: {
        max: 3,
        retryIf: (error) => !!error,
      },
    }),
    new HttpLink({
      uri: '/graphql',
    }),
  ]),

  cache: new InMemoryCache({
    // TODO: Can be automated through
    // https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
    possibleTypes: {
      Question: [
        'TastesQuestion',
        'SliderQuestion',
        'ListQuestion',
        'OpenQuestion',
      ],
    },
  }),
});

export default client;
