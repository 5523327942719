import React, { FunctionComponent } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Container } from '@mui/material';
import Loading from './Loading';
import useClientTheme from '../Hooks/useClientTheme';
import { loadContentsquare } from '../Common/contentsquare';

type LayoutProps = {
  children: React.ReactNode
};

const Layout: FunctionComponent<LayoutProps> = ({ children }) => {
  const { themeData, themeLoading } = useClientTheme();

  if (themeLoading) return <Loading />;

  if (!themeData) return <p>Something went wrong</p>;

  if (themeData.contentsquareId) {
    loadContentsquare(themeData.contentsquareId);
  }

  return (
    <HelmetProvider>
      <Helmet>
        {themeData.blueconicScriptURL && (
          <script src={themeData.blueconicScriptURL} />
        )}

        <title>
          {`${themeData.name} - Smaaktest`}
        </title>
      </Helmet>
      <Container style={{ maxWidth: themeData.maxWidth, padding: '20px 10px' }}>
        {children}
      </Container>
    </HelmetProvider>
  );
};

export default Layout;
