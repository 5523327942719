import { TasteProfile, ThemeQuery } from '@generated/graphql';

const getProfileDeeplink = (themeData: ThemeQuery['theme'], profile: TasteProfile, token: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  const landingPage = searchParams.get('landingPage') || themeData?.landingPages?.find(({ key }) => key === profile)?.value;
  const prefix = themeData?.landingPageQueryPrefix;

  if (!landingPage || !prefix) return '';

  return `${landingPage}?${prefix}profile=${profile}&${prefix}token=${token}`;
};
export default getProfileDeeplink;
