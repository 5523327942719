const loadBlueConicClient = (): Promise<void> => {
  if (typeof window.blueConicClient?.event?.subscribe !== 'undefined') {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    window.addEventListener('onBlueConicLoaded', () => resolve());
    setTimeout(reject, 2000);
  });
};

const distanceToPercentage = (distance: number) => (
  Math.round(100 - distance / 2.7)
);

interface Match {
  sku: string;
  difference: number;
}

export const setBcMatches = async (token: string, matches: Match[]) => {
  try {
    await loadBlueConicClient();
  } catch {
    return Promise.reject(new Error('Failed to load BlueConic'));
  }

  const profile = window.blueConicClient.profile.getProfile();
  const matchesProperty = 'all_-_tasteindex-profile_values';
  const tokenProperty = 'all_-_tasteindex_token_identifier';
  const values = matches?.map((match) => `${match.sku}:${distanceToPercentage(match.difference)}`);

  return new Promise<void>((resolve, reject) => {
    profile.loadValues([tokenProperty, matchesProperty], this, () => {
      profile.setValue(tokenProperty, token);
      profile.setValues(matchesProperty, values);
      window.blueConicClient.profile.updateProfile(this, () => resolve());
    });
    setTimeout(() => reject(new Error('Failed to update BlueConic profile')), 3000);
  });
};
