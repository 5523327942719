import React, { FunctionComponent } from 'react';
import {
  Box, styled, Tooltip, Typography,
} from '@mui/material';
import { ReactComponent as InfoIcon } from '../Assets/svgs/info-icon.svg';

interface IDashedProgress {
  progress?: number | null,
  title: string,
  infoText: string,
}

const Info = styled(InfoIcon)({
  height: 14,
  left: 4,
  position: 'relative',
  top: '-4px',
  width: 14,
});

const DashedProgress: FunctionComponent<IDashedProgress> = ({ progress = 0, title, infoText }) => (
  <>
    <Tooltip
      title={infoText}
      style={{ background: 'white' }}
      placement="bottom-start"
      sx={{
        background: 'white',
        color: 'black',
      }}
      enterTouchDelay={10}
      leaveTouchDelay={3000}
    >
      <Typography
        variant="h6"
        style={{ background: 'transparent' }}
        sx={{
          color: 'text.primary',
          height: 26,
          padding: '0 8px',
          '&:hover': {
            color: 'text.primary',
          },
        }}
      >
        {title}
        <Info />
      </Typography>
    </Tooltip>
    <Box
      sx={{
        borderBottom: '2px dashed black',
        marginBottom: '50px',
        position: 'relative',
        '&:before, &:after': {
          background: 'black',
          content: "''",
          display: 'block',
          height: '10px',
          position: 'absolute',
          top: '5px',
          width: '4px',
          zIndex: 100,
        },
        '&:after': {
          right: 0,
        },
      }}
    >
      <Box
        sx={{
          background: 'black',
          borderRadius: 5,
          display: 'block',
          height: '10px',
          left: '-5px',
          position: 'relative',
          top: '6px',
          width: '10px',
          marginLeft: `${progress}%`,
        }}
      />
    </Box>
  </>
);
export default DashedProgress;
