import React, { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
  Box, Grid, Typography, Link, LinearProgress, Button, useTheme, CircularProgress,
} from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import {
  useDestroyAnswerMutation, useParticipationQuery, useUpsertAnswerMutation,
} from '@generated/graphql';
import { LanguageSelector, Loading, TasteCard } from '../Components';
import useClientTheme from '../Hooks/useClientTheme';
import { tasteImageUrl } from '../Common/tasteImage';
import getTranslation from '../Common/translation';
import SliderQuestionView from './Questions/SliderQuestionView';
import { AnswerData } from '../Common/answer-data';
import { ListQuestionView } from './Questions/ListQuestionView';
import { TastesQuestionView } from './Questions/TastesQuestionView';
import OpenQuestionView from './Questions/OpenQuestionView';

const Questionnaire = () => {
  const theme = useTheme();
  const { themeData, themeLoading } = useClientTheme();

  const [givenAnswers, setGivenAnswers] = useState<Array<{ type: 'upsert', answerData: AnswerData, executed: boolean, questionId: number }>>([]);

  const navigate = useNavigate();
  const { step: stepParam, substep: substepParam, token: tokenParam } = useParams();
  const step = Number(stepParam);
  const substep = Number(substepParam);
  const token = String(tokenParam);
  const { data: participation, loading: loadingParticipation } = useParticipationQuery({ variables: { token } });
  const [upsertAnswerMutation, { loading: loadingUpsertAnswerMutation }] = useUpsertAnswerMutation();
  const [destroyAnswerMutation] = useDestroyAnswerMutation();
  const questions = useMemo(() => participation?.participation?.questionnaire?.questions || [], [participation]);
  const requiredAnswers = questions.length;
  const progressPercentage = useMemo(() => (step / requiredAnswers) * 100, [step, requiredAnswers]);
  type Question = (typeof questions)[number] | null | undefined;
  const currentQuestion: Question = useMemo(() => (substep ? questions[step]?.alternativeQuestion : questions[step]), [questions, step, substep]);
  const previousSubsteps: number[] = JSON.parse(sessionStorage.getItem('PreviousSubsteps') ?? '[]');
  const previousSlug = substep ? `/${step}/${substep - 1}/${token}` : `/${step - 1}/${previousSubsteps[previousSubsteps.length - 1]}/${token}`;
  const hasAlternative = !!currentQuestion?.alternativeQuestion;

  useEffect(() => {
    if (!themeData) return;

    const preloadImages = (question: Question) => {
      if (question?.__typename === 'TastesQuestion') {
        new Image().src = tasteImageUrl(themeData.imageSet, question.firstTaste);
        new Image().src = tasteImageUrl(themeData.imageSet, question.secondTaste);
      }
    };

    if (step + 1 < requiredAnswers) {
      preloadImages(questions[step + 1]);
    }
    if (hasAlternative) {
      preloadImages(currentQuestion.alternativeQuestion);
    }
  }, [currentQuestion?.alternativeQuestion, hasAlternative, questions, requiredAnswers, step, themeData]);

  const markTaskAsExecuted = (questionId: number) => {
    setGivenAnswers((oldValue) => ({
      ...oldValue, [questionId]: { ...oldValue[questionId], executed: true },
    }));
  };

  const uploadAnswer = (questionId: number, answerData: AnswerData) => upsertAnswerMutation({
    variables: {
      questionId,
      token,
      ...answerData,
    },
  }).then(() => {
    markTaskAsExecuted(questionId);
  });

  const destroyAnswer = async (questionId: number) => {
    destroyAnswerMutation({
      variables: {
        questionId,
        token,
      },
    });

    markTaskAsExecuted(questionId);
  };

  const uploadAnswers = async () => Promise.allSettled(Object.values(givenAnswers).filter((givenAnswer) => !givenAnswer.executed).map((givenAnswer) => {
    if (givenAnswer.type === 'upsert') {
      return uploadAnswer(givenAnswer.questionId, givenAnswer.answerData);
    }

    return null;
  }));

  const handleAnswer = async (questionId: number, answerData: AnswerData) => {
    setGivenAnswers((oldValue) => ({
      ...oldValue,
      [questionId]: {
        answerData, executed: false, type: 'upsert', questionId,
      },
    }));

    if (step + 1 === requiredAnswers) {
      sessionStorage.removeItem('PreviousSubsteps');

      await uploadAnswer(questionId, answerData);
      await uploadAnswers();
      navigate(`/result/${token}`, { replace: true });
    } else {
      sessionStorage.setItem('PreviousSubsteps', JSON.stringify([...previousSubsteps, substep]));
      navigate(`/${step + 1}/0/${token}`, { replace: true });

      uploadAnswer(questionId, answerData);
    }
  };

  const skipAnswer = async () => {
    if (currentQuestion?.id) await destroyAnswer(currentQuestion.id);

    navigate(`/${step}/${substep + 1}/${token}`, { replace: true });
  };

  const handleBack = async () => {
    if (currentQuestion?.id) await destroyAnswer(currentQuestion.id);

    if (!substep) {
      sessionStorage.setItem('PreviousSubsteps', JSON.stringify(previousSubsteps.slice(0, -1)));
    }
  };

  if (themeLoading || loadingParticipation) return <Loading />;

  if (!themeData) return <p>Something went wrong</p>;
  if (!currentQuestion) return <p>Something went wrong with selecting a currentQuestion</p>;

  const translation = getTranslation(themeData);

  if (themeData.questionVariant === 'QUIET') {
    return (
      <Box textAlign="center">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CircularProgress size="1rem" sx={{ color: 'grey.300', visibility: loadingUpsertAnswerMutation ? 'visible' : 'hidden' }} />
          <LanguageSelector
            languages={themeData.translations.map((t) => t.language)}
            defaultLanguage={themeData.defaultLanguage}
          />
        </Box>
        <Typography variant="h2" gutterBottom>{translation.questionTitle}</Typography>
        <Typography variant="h5" gutterBottom>{translation.questionSubtitle}</Typography>

        {currentQuestion.__typename === 'TastesQuestion' && (
          <TastesQuestionView question={currentQuestion} onAnswerSubmit={handleAnswer} translation={translation} />
        )}

        {currentQuestion.__typename === 'SliderQuestion' && (
          <SliderQuestionView question={currentQuestion} onAnswerSubmit={handleAnswer} />
        )}

        {currentQuestion.__typename === 'OpenQuestion' && (
          <OpenQuestionView question={currentQuestion} onAnswerSubmit={handleAnswer} />
        )}

        {currentQuestion.__typename === 'ListQuestion' && (
          <ListQuestionView question={currentQuestion} onAnswerSubmit={handleAnswer} />
        )}

        {hasAlternative && (
          <Box marginBottom={4}>
            <Button onClick={skipAnswer} variant="text" sx={{ background: 'transparent', color: 'text.primary' }}>
              {translation.questionSkip}
            </Button>
          </Box>
        )}

        <LinearProgress variant="determinate" value={progressPercentage} />
      </Box>
    );
  }

  if (themeData.questionVariant === 'BORDERED') {
    return (
      <Box textAlign="left">
        <Box display="flex" justifyContent="space-between">
          <Link
            component={RouterLink}
            onClick={handleBack}
            to={previousSlug}
            replace
            underline="none"
            variant="body2"
            color="inherit"
            visibility={step || substep ? 'visible' : 'hidden'}
            sx={{
              alignItems: 'center', display: 'flex', py: 1, mb: 1,
            }}
          >
            <ChevronLeft fontSize="small" />
            {/* TODO: Add to translations */}
            {translation.language === 'en' ? 'Back' : 'Vorige vraag'}
          </Link>
          <CircularProgress size="1rem" sx={{ color: 'grey.300', visibility: loadingUpsertAnswerMutation ? 'visible' : 'hidden' }} />
          <LanguageSelector
            languages={themeData.translations.map((t) => t.language)}
            defaultLanguage={themeData.defaultLanguage}
          />
        </Box>

        {currentQuestion.__typename === 'TastesQuestion' && (
          <Grid
            container
            marginBottom={2}
            columnSpacing={2}
          >
            <Grid item xs={6}>
              <TasteCard
                taste={currentQuestion.firstTaste}
                language={translation.language}
                onClick={() => handleAnswer(currentQuestion.id, { tasteId: currentQuestion.firstTaste.id })}
              />
            </Grid>
            <Grid item xs={6}>
              <TasteCard
                taste={currentQuestion.secondTaste}
                language={translation.language}
                onClick={() => handleAnswer(currentQuestion.id, { tasteId: currentQuestion.secondTaste.id })}
              />
            </Grid>
          </Grid>
        )}

        {currentQuestion.__typename === 'SliderQuestion' && (
          <SliderQuestionView question={currentQuestion} onAnswerSubmit={handleAnswer} />
        )}

        {currentQuestion.__typename === 'OpenQuestion' && (
          <OpenQuestionView question={currentQuestion} onAnswerSubmit={handleAnswer} />
        )}

        {currentQuestion.__typename === 'ListQuestion' && (
          <ListQuestionView question={currentQuestion} onAnswerSubmit={handleAnswer} />
        )}

        <Box sx={{ textAlign: 'center' }} visibility={hasAlternative ? 'visible' : 'hidden'}>
          <Button
            onClick={skipAnswer}
            sx={{
              background: '#FFFFFF',
              color: '#555555',
              borderRadius: 1,
              textTransform: 'none',
              '&:hover': {
                background: '#FFFFFF',
                color: '#555555',
              },
            }}
          >
            {translation.questionSkip}
          </Button>
        </Box>
        <Box
          textAlign="right"
          sx={{
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexFlow: 'column-reverse',
              mt: 2,
              textAlign: 'center',
            },
          }}
        >
          <Box>
            {`${step} / ${requiredAnswers}`}
          </Box>
          <LinearProgress
            variant="determinate"
            value={progressPercentage}
            color="secondary"
            sx={{
              height: 14,
              borderRadius: 4,
              background: 'white',
              border: '2px solid #CCCCCC',
              marginY: 1,
            }}
          />
        </Box>
      </Box>
    );
  }
  return null;
};

export default Questionnaire;
