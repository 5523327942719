import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface Props {
  center?: boolean
  pad?: boolean
}

const Loading = ({ center, pad }: Props) => (
  <Box
    display="flex"
    justifyContent={center ? 'center' : 'flex-start'}
    mt={pad ? 3 : undefined}
    mb={pad ? 3 : undefined}
  >
    <CircularProgress />
  </Box>
);

export default Loading;
