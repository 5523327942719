import React from 'react';

import {
  Grid,
} from '@mui/material';

import { Taste, TastesQuestion } from '@generated/graphql';
import { TasteCard } from '../../Components';
import { AnswerData } from '../../Common/answer-data';

import getTranslation from '../../Common/translation';

type TastesQuestionProps = {
  question: Omit<TastesQuestion, 'firstTaste' | 'secondTaste'> & { firstTaste: Pick<Taste, 'id' | 'name' | 'displayName'>, secondTaste: Pick<Taste, 'id' | 'name' | 'displayName'> },
  onAnswerSubmit: (question: number, answerData: AnswerData) => void,
  translation: ReturnType<typeof getTranslation>
};

// Component to render a tastes question
export const TastesQuestionView = ({ question, onAnswerSubmit, translation }: TastesQuestionProps) => (
  <Grid
    container
    marginBottom={4}
    justifyContent="space-around"
  >
    <Grid item xs={6}>
      <TasteCard
        taste={question.firstTaste}
        language={translation.language}
        onClick={() => onAnswerSubmit(question.id, { tasteId: question.firstTaste.id })}
      />
    </Grid>
    <Grid item xs={6}>
      <TasteCard
        taste={question.secondTaste}
        language={translation.language}
        onClick={() => onAnswerSubmit(question.id, { tasteId: question.secondTaste.id })}
      />
    </Grid>
  </Grid>
);
