import { Construction } from '@mui/icons-material';
import { Typography } from '@mui/material';

const UnderConstruction = () => (
  <>
    <Typography component="h1" variant="h4">
      <Construction sx={{ marginRight: 1 }} />
      We&rsquo;ll be back soon!
    </Typography>
    <Typography>
      Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.
    </Typography>
    <Typography>Please try again later.</Typography>
  </>
);

export default UnderConstruction;
