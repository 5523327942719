import React, { FunctionComponent } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import client from './Common/apollo';
import Theme from './Components/Theme';
import { Layout } from './Components';
import * as Views from './Views';
import './styles.css';

const App: FunctionComponent = () => {
  // eslint-disable-next-line no-console
  console.log(`[APP] Version ${process.env.REACT_APP_VERSION}`);

  return (
    <ApolloProvider client={client}>
      <Theme>
        <HashRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<Views.Start />} />
              <Route path="/:step/:substep/:token" element={<Views.Question />} />
              <Route path="/result/:token" element={<Views.Result />} />
            </Routes>
          </Layout>
        </HashRouter>
      </Theme>
    </ApolloProvider>
  );
};

export default Sentry.withProfiler(App);
