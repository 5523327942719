import React, { FunctionComponent, MouseEvent, useState } from 'react';
import {
  Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem,
} from '@mui/material';
import { Translate } from '@mui/icons-material';
import 'flag-icons/css/flag-icons.min.css';
import { getCurrentLanguage } from '../Common/translation';

interface LanguageSelectorProps {
  languages: string[];
  defaultLanguage?: string | null;
}

const mapping: { [key: string]: { name: string, icon: string } } = {
  nl: { name: 'Nederlands', icon: 'nl' },
  en: { name: 'English', icon: 'gb' },
  he: { name: 'עִבְרִית', icon: 'il' },
};

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({ languages, defaultLanguage }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (languages.length < 2) return null;

  const searchParams = new URLSearchParams(window.location.search);
  const active = getCurrentLanguage({ defaultLanguage }) || languages[0];
  const setLanguage = (language: string) => {
    searchParams.set('language', language);
    window.location.search = searchParams.toString();
    handleClose();
  };

  return (
    <Box>
      <IconButton onClick={handleClick} disableRipple>
        {mapping[active]
          ? <span className={`fi fi-${mapping[active].icon}`} style={{ height: 16 }} /> // <img src={mapping[active].icon} alt="" height={16} />
          : <Translate />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((language) => (
          <MenuItem key={language} onClick={() => setLanguage(language)}>
            <ListItemIcon>
              <span className={`fi fi-${mapping[language]?.icon || 'xx'}`} style={{ borderRadius: 4 }} />
            </ListItemIcon>
            <ListItemText>{mapping[language]?.name || language}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageSelector;
