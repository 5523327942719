import React, { FunctionComponent } from 'react';
import {
  Button, Card, CardActionArea, CardContent, CardMedia, Typography, useTheme,
} from '@mui/material';
import useClientTheme from '../Hooks/useClientTheme';
import { tasteImageUrl } from '../Common/tasteImage';

type TasteCardProps = {
  taste: {
    name: string,
    displayName?: { [key: string]: string } | null,
  },
  language: string,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
};

const TasteCard: FunctionComponent<TasteCardProps> = ({ taste, language, onClick }) => {
  const theme = useTheme();
  const { themeData, themeLoading } = useClientTheme();

  if (themeLoading || !themeData) return null;

  const displayName = (taste.displayName && taste.displayName[language]) || taste.name;

  if (themeData.cardVariant === 'BUTTON') {
    return (
      <Card elevation={0}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            component="img"
            image={tasteImageUrl(themeData.imageSet, taste)}
            alt={taste.name}
            style={{ objectFit: 'contain' }}
            sx={{ maxHeight: 260 }}
          />
          <CardContent style={{ textAlign: 'center' }} sx={{ [theme.breakpoints.down('sm')]: { padding: 1 } }}>
            <Button
              component="span"
              style={{ wordBreak: 'break-word' }}
            >
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: displayName }} />
            </Button>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  if (themeData.cardVariant === 'TEXT') {
    return (
      <Card elevation={0} variant="outlined" sx={{ borderColor: '#EB6C10' }}>
        <CardActionArea onClick={onClick}>
          <CardMedia
            component="img"
            height="240"
            image={tasteImageUrl(themeData.imageSet, taste)}
            alt={taste.name}
            style={{ objectFit: 'contain' }}
            sx={{
              [theme.breakpoints.down('sm')]: {
                height: 120,
              },
            }}
          />
          <CardContent>
            <Typography variant="h4">
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: displayName }} />
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return null;
};

export default TasteCard;
