import React, { useEffect, useState } from 'react';

import {
  Button, Checkbox, FormControlLabel, FormGroup, Typography, FormControl, RadioGroup, Radio, Box,
} from '@mui/material';

import { ListQuestion } from '@generated/graphql';
import { AnswerData } from '../../Common/answer-data';

type ListQuestionProps = {
  question: ListQuestion,
  onAnswerSubmit: (question: number, answerData: AnswerData) => void
};

const getCheckedState = (question: ListQuestionProps['question']) => Object.fromEntries(question.possibleAnswers.map((answer) => [answer, false]));

// Component to render a multiple choice question
export const ListQuestionView = ({ question, onAnswerSubmit }: ListQuestionProps) => {
  const [value, setValue] = useState<string>('');
  const [checked, setChecked] = useState(getCheckedState(question));

  useEffect(() => {
    setChecked(getCheckedState(question));
    setValue('');
  }, [question]);

  const FormWrapperComponent = question.multipleAnswersAllowed ? FormGroup : RadioGroup;
  const FormControlComponent = question.multipleAnswersAllowed ? Checkbox : Radio;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (question.multipleAnswersAllowed) {
      setChecked({
        ...checked,
        [event.target.value]: event.target.checked,
      });
    } else {
      setValue((event.target as HTMLInputElement).value);
    }
  };

  const handleClick = () => {
    onAnswerSubmit(question.id, { value: value || Object.entries(checked).filter(([, isChecked]) => isChecked).map(([key]) => key) });
  };

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 8 }}>{question.name}</Typography>

      <FormControl style={{ display: 'block' }}>
        <FormWrapperComponent value={value} onChange={handleChange}>
          {question.possibleAnswers.map((answer) => (
            <FormControlLabel key={answer} control={<FormControlComponent />} value={answer} label={answer} />
          ))}
        </FormWrapperComponent>
      </FormControl>

      <Box textAlign="center">
        <Button sx={{ mt: 4, mb: 1 }} onClick={handleClick} disabled={!value && !Object.values(checked).some(Boolean)}>
          Doorgaan
        </Button>
      </Box>
    </>
  );
};
