/* eslint-disable no-underscore-dangle */
export const loadContentsquare = (id: string) => {
  window._uxa = window._uxa || [];
  try {
    if (typeof window.page_type !== 'undefined') {
      window._uxa.push(['setCustomVariable', 1, 'page_type', window.page_type, 3]);
    }
  } catch {
    // Never mind
  }

  if (typeof window.CS_CONF === 'undefined') {
    window._uxa.push(['setPath', window.location.pathname + window.location.hash.replace('#', '?__')]);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `//t.contentsquare.net/uxa/${id}.js`;
    document.getElementsByTagName('head')[0].appendChild(script);
  } else {
    window._uxa.push(['trackPageview', window.location.pathname + window.location.hash.replace('#', '?__')]);
  }
};
