import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box, Grid, Link, LinearProgress, Typography, useTheme,
} from '@mui/material';
import { ArrowForward, ChevronLeft, ChevronRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useCreateClickMutation, useRespondentResultQuery } from '@generated/graphql';
import { Loading, DashedProgress } from '../Components';
import useClientTheme from '../Hooks/useClientTheme';
import { setBcMatches } from '../Common/blueconic';
import getProfileDeeplink from '../Common/profile-deeplink';
import getTranslation, { getCurrentLanguage, translateProfile } from '../Common/translation';

const Result = () => {
  const theme = useTheme();
  const { themeData, themeLoading } = useClientTheme();

  const { token: tokenParam } = useParams();
  const token = String(tokenParam);
  const { data, loading: loadingResult } = useRespondentResultQuery({ variables: { token }, fetchPolicy: 'no-cache' });
  const [createClickMutation] = useCreateClickMutation();
  const [loadingBlueconic, setLoadingBlueconic] = useState(true);

  const result = data?.respondent;
  const bareToken = result?.token;
  const profile = result?.tasteProfile;
  const matches = result?.matches;

  const deeplink = useMemo(() => {
    if (!profile || !bareToken) return '';
    return getProfileDeeplink(themeData, profile, bareToken);
  }, [bareToken, profile, themeData]);

  const openDeeplink = useCallback(async () => {
    if (!deeplink) return;

    try {
      await createClickMutation({
        variables: {
          url: deeplink,
          token,
        },
      });
    } finally {
      window.open(deeplink, '_parent');
    }
  }, [createClickMutation, deeplink, token]);

  useEffect(() => {
    if (themeData && !themeData.blueconicScriptURL) {
      setLoadingBlueconic(false);
    }
    if (themeData?.blueconicScriptURL && bareToken && matches) {
      const setMatches = async () => {
        try {
          await setBcMatches(bareToken, matches);
        } catch {
          // No matches set
        }
        setLoadingBlueconic(false);
      };
      setMatches();
    }
  }, [bareToken, matches, themeData, themeData?.blueconicScriptURL]);

  useEffect(() => {
    if (themeData?.resultVariant === 'REDIRECT' && !loadingBlueconic) {
      openDeeplink();
    }
  }, [loadingBlueconic, openDeeplink, themeData]);

  const handleOnClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    openDeeplink();
  };

  if (themeLoading || loadingResult) return <Loading />;

  if (!profile) return <p>Jouw profiel kon niet worden geladen.</p>;
  if (!result) return <p>Jouw resultaat konden niet worden geladen.</p>;
  if (!themeData) return <p>De themagegevens konden niet worden geladen.</p>;

  const translation = getTranslation(themeData);

  if (themeData.resultVariant === 'REDIRECT') {
    return (
      <Box textAlign="center">
        <Typography variant="h2" gutterBottom>{translation.resultTitle}</Typography>

        <LoadingButton
          href={deeplink}
          target="_parent"
          loading={loadingBlueconic}
        >
          {translation.resultButtonText}
        </LoadingButton>
      </Box>
    );
  }

  if (themeData.resultVariant === 'SIMPLE') {
    return (
      <Box textAlign="center">
        <Typography variant="h2" gutterBottom>{translation.resultTitle}</Typography>
        <Typography variant="h5" marginBottom={4} color="black">
          {translation.resultSubtitle}
        </Typography>
        <LoadingButton
          style={{ minWidth: 200 }}
          size="large"
          href={deeplink}
          target="_parent"
          onClick={handleOnClick}
          loading={loadingBlueconic}
        >
          {translation.resultButtonText}
        </LoadingButton>

        {themeData.showVisualisation && (
          <div style={{
            minWidth: 200, marginTop: 24, fontSize: 12,
          }}
          >
            <a
              href={`${process.env.REACT_APP_VISUALISATION_URL}/?token=${token}&language=${getCurrentLanguage(themeData)}`}
              target="_blank"
              rel="noreferrer"
            >
              Show 3D visualisation
            </a>
          </div>
        )}
        <Box height={100} />
        <LinearProgress variant="determinate" value={100} />
      </Box>
    );
  }

  if (themeData.resultVariant === 'SCORES') {
    const resultTexts = themeData?.resultTexts?.find(({ key }) => key === profile)?.value;

    return (
      <>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Link
            component={RouterLink}
            color="inherit"
            to="/"
            underline="none"
            variant="body2"
            style={{
              alignItems: 'center', display: 'flex', marginBottom: 12, position: 'relative', zIndex: 10,
            }}
          >
            <ChevronLeft fontSize="small" />
            Restart the test
          </Link>
          {themeData.showVisualisation && (
            <Link
              href={`${process.env.REACT_APP_VISUALISATION_URL}/?token=${token}&language=${getCurrentLanguage(themeData)}`}
              target="_blank"
              rel="noreferrer"
              color="inherit"
              underline="none"
              variant="body2"
              style={{
                alignItems: 'center', display: 'flex', marginBottom: 12, position: 'relative', zIndex: 10,
              }}
            >
              View your 3D-taste visualisation
              <ChevronRight fontSize="small" />
            </Link>
          )}
        </Box>
        <Grid
          container
          marginBottom={4}
          justifyContent="space-between"
          spacing={4}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{
              background: '#fff',
              padding: 2,
              [theme.breakpoints.down('md')]: {
                background: 'transparent',
                padding: 0,
              },
            }}
            >
              <Box sx={{
                position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}
              >
                <img
                  src={`/uploads/profile/photo/${profile.toLowerCase()}.png`}
                  alt={profile.toLowerCase()}
                  style={{
                    objectFit: 'contain', width: '100%', height: 256, marginBottom: 20,
                  }}
                />
                <Box
                  sx={{
                    color: '#FFFFFF',
                    fontSize: themeData.fontSize * 2,
                    fontWeight: 900,
                    left: 0,
                    textAlign: 'center',

                    top: 0,
                    width: '100%',
                    height: '4rem',
                    lineHeight: '4rem',
                    bottom: 0,
                    margin: 'auto',
                    position: 'absolute',
                  }}
                >
                  {translateProfile(profile)}
                </Box>
              </Box>
              <Typography variant="h6">
                What does your taste profile mean?
              </Typography>
              <p style={{ marginTop: 4 }}>
                {resultTexts}
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'space-between',
                height: '100%',
                alignItems: 'flex-end',
                mt: 2,
                [theme.breakpoints.down('md')]: {
                  flexFlow: 'column-reverse',
                  alignItems: 'flex-start',
                  button: {
                    marginBottom: 2,
                  },
                  mt: 0,
                  '> div': {
                    background: 'transparent !important',
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                  },
                },
              }}
            >
              <div style={{ padding: 24, background: 'rgba(196, 196, 196, 0.2)', width: '100%' }}>
                <DashedProgress
                  title="Richness"
                  infoText="Flavor richness indicates the quantity and intensity of taste. As taste matures, flavor richness increases."
                  progress={result.richnessScore}
                />
                <DashedProgress
                  title="Texture"
                  infoText="Texture addresses taste elements found in for example acidity. A 'tight' mouthfeel is smooth and astringent, like when eating a lemon."
                  progress={result.textureScore}
                />
                <DashedProgress
                  title="Body"
                  infoText="Body addresses taste elements found in for example sugar and fat. A Chardonnay wine will have a higher body score than for example a glass of mineral water."
                  progress={result.bodyScore}
                />
              </div>
              <LoadingButton
                href={deeplink}
                target="_parent"
                onClick={handleOnClick}
                loading={loadingBlueconic}
              >
                {translation.resultButtonText}
                <ArrowForward sx={{ position: 'relative', top: 2 }} />
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  return null;
};

export default Result;
