import React, { useState } from 'react';

import {
  Box, Button, TextField, Typography,
} from '@mui/material';

import { OpenQuestion } from '@generated/graphql';
import { AnswerData } from '../../Common/answer-data';

type OpenQuestionProps = {
  question: OpenQuestion,
  onAnswerSubmit: (question: number, answerData: AnswerData) => void
};

// Component to render an open question
const OpenQuestionView = ({ question, onAnswerSubmit }: OpenQuestionProps) => {
  const [value, setValue] = useState('');

  return (
    <>
      <Typography variant="h2" sx={{ marginBottom: 8 }}>{question.name}</Typography>

      <Box sx={{ width: '100%', px: 4 }}>
        <TextField
          margin="normal"
          fullWidth
          label={question.name}
          onChange={({ target }) => setValue(target.value)}
          autoFocus
        />
      </Box>

      <Box textAlign="center">
        <Button sx={{ mt: 8, mb: 1 }} onClick={() => onAnswerSubmit(question.id, { value })} disabled={!value}>
          Doorgaan
        </Button>
      </Box>
    </>
  );
};

export default OpenQuestionView;
